body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', 'Gotham', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
}
html{
  font-size: 16px;
  background: #e4eef4ca;
}
@media (min-width: 540px) {
  html {
    font-size: 30%;
    /* 18px */
  }
}
@media (min-width: 720px) {
  html {
    font-size: 40%;
    /* 18px */
  }
}
@media (min-width: 822px) {
  html {
    font-size: 45%;
    /* 18px */
  }
}
@media (min-width: 960px) {
  html {
    font-size: 55%;
    /* 18px */
  }
}
@media (min-width: 1028px) {
  html {
    font-size: 40%;
    /* 18px */
  }
}
@media (min-width: 1152px) {
  html {
    font-size: 80%;

  }
}
/* @media (min-width: 1309px) {

  html {
    font-size: 70%; 
  
  }
} */

@media (min-width: 1520px) {

  html {
    font-size: 100%; 
    /* zoom: 1;  */
  }
}

@media (min-width: 1920px) {

  html {
    font-size: 120%; 
    zoom: 1; 
  }
}



@media (min-width: 2880px) {

  html {
    font-size: 150%; 
    zoom: 1; 
    background: #f0f3f5;
  }
}

@media (min-width: 4320px) {

  html {
    font-size: 240%; 
    zoom: 1; 
  }
}
@media (min-width: 5760px) {

  html {
    font-size: 340%; 
    zoom: 1; 
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*::-webkit-scrollbar {

  -webkit-appearance: none;
}

*::-webkit-scrollbar:vertical {
  width: 0.7vh;
}

*::-webkit-scrollbar-button:increment,
.contenedor::-webkit-scrollbar-button {
  display: none;
}

*::-webkit-scrollbar:horizontal {
  height: 5px;
}

*::-webkit-scrollbar-thumb {
  background: #1e2225d2;
  border-radius: 8px;
  background-color: #1e22258a;
  /* border:1px solid #A6C1D3; */
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
}