.title{
    font-weight: 700;
    color: #0D263B;
    font-size: 1.2rem;
    padding-left: 5px;
    margin: 0;
}


.customButton1 {
    color: green;
    padding: 4px;
    width: fit-content;
    background: transparent;
    border: solid 1px green;
    transition: all 0.3s ease;
      cursor: pointer;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
  font-size: 0.80rem;
  
  }
  .customButton2 {
    color: orange;
    padding: 4px;
    background: transparent;
    /* border: solid 1px orange; */
    transition: all 0.3s ease;
      cursor: pointer;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
  font-size: 0.80rem;
  }
  
  .customButton2:hover {
      transform: scale(1.05);
    }
    
    .customButton2:active {
      transform: scale(1);
    }
  
    .customButton1:hover {
      transform: scale(1.05);
    }
    
    .customButton1:active {
      transform: scale(1);
    }


    .header{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }