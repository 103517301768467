.responsiveContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start; 
    padding-left: 80px; 
  }
  
  .responsiveText {
    font-style: italic;
    color: #666;
  }
  
  @media (max-width: 600px) {
    .responsiveContainer {
      justify-content: center; 
      padding: 10px;
    }
  }
  