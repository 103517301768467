.layout {
    width: 100%;
    min-height: 100vh;
    height: fit-content;
    display: flex;
    justify-content: center;
    background: linear-gradient(90deg, rgba(141, 173, 195, 0.519) 30%, rgba(166, 193, 211, 0.41) 50%, #F4FBFF 50%, #FFFF 100%);

    align-items: center;
}


.container {
    position: relative;
    width: 75%;
    height: 75vh;
    border-radius: 10px;
    background: #F4FBFF;
    box-shadow: 0px 4px 10px 0px rgba(8, 71, 143, 0.25);
    display: flex;
    flex-direction: row;
}

.containerPassword {

    width: fit-content;
    min-width: 30%;
    height: auto;
    border-radius: 10px;
    background: #F4FBFF;

    box-shadow: 0px 4px 10px 0px rgba(8, 71, 143, 0.25);
    justify-content: space-around;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 2vh;
    padding: 2;
}

.layoutPassword {

    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    /* background-color: linear-gradient(90deg, rgba(141, 173, 195, 0.519) 30%, rgba(95, 162, 207, 0.41) 50%); */
    background-color: #A6C1D3;
    background-repeat: no-repeat;
    background-image: url("https://res.cloudinary.com/dvztuncle/image/upload/v1693265954/Group_3_lfrk9b.png");
    align-items: center;
}

.divImg {
    width: 50%;
    height: 100%;
    background-color: #A6C1D3;
    background-image: url("https://res.cloudinary.com/dvztuncle/image/upload/v1693261074/Frame_33_5_x2rgp2.png");
    /* background-image: url("https://res.cloudinary.com/dvztuncle/image/upload/v1693162316/Frame_33_3_cpeinz.png"); */
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.divImgSvg {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    z-index: 0;
}

.title {
    color: #0B315E;
    text-align: center;
    font-family: Montserrat;
    font-size: 2.5vh;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.subTitle {
    /* font-size:medium;
    font-weight: 800;
    color: rgba(255, 255, 255, 0.78); */
    color: rgba(255, 255, 255, 0.78);
    text-align: center;
    font-family: Montserrat;
    font-size: 2vh;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.divForm {
    display: flex;
    justify-content:center;
    align-items: center;
    font-size: 18vh;
    font-weight: 700;
    color: #5A5A5D;
    flex-direction: column;
    width: 50%;
}


.containerInputs {
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 1vh;

    align-items: left;
    width: 60%;
    justify-content: center;
}

.form {
    display: flex;
    gap: 2vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.img {
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2vh;
}

.divHelp {
    position: absolute;
    bottom: 0;
    left: 0;
    padding-left: 2vh;
    padding-bottom: 2vh;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: start;

}

.divIcons {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.8vh;
    margin: 0;
    font-weight: 400;
    gap: 2vh;
    color: #F4FBFF;

}

.logo {
    width: 25vh;
}

@media (max-width: 1000px) {

    .layout {
    
        background: linear-gradient(271deg, rgba(166, 193, 211, 0.62) 0%, rgba(166, 193, 211, 0.73) 34.37%, rgba(166, 193, 211, 0.79) 46.35%, #A6C1D3 100%);
        background-image: url("https://res.cloudinary.com/dvztuncle/image/upload/v1693265954/Group_3_lfrk9b.png");
        background-color: #A6C1D3;
        /* background-color: linear-gradient(271deg, rgba(166, 193, 211, 0.62) 0%, rgba(166, 193, 211, 0.73) 34.37%, rgba(166, 193, 211, 0.79) 46.35%, #A6C1D3 100%); */
        /* background-image: url("https://res.cloudinary.com/dvztuncle/image/upload/v1693252358/Group_2z_xki3b7.png"); */
        background-size: contain;
    }

    .divImg {
        display: none;
    }

    .container {
        flex-direction: column;
        height: auto;
    }

    .divImgSvg {
        display: none;
    }

    .divForm {
        width: 100%;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 950px) {
    
    .containerPassword {

        width: 70%;
    }
}

@media (max-width: 850px) {
    .logo {
        width: 15vh;
    }

    .layout {
    
        background-image: url("https://res.cloudinary.com/dvztuncle/image/upload/v1693265954/Group_3_lfrk9b.png");
        background-repeat: no-repeat;
        background-size: cover;

    }

    .containerPassword {

        width: 90%;
    }

    .container {

        width: 90%;
    }
    .containerInputs {
        width: 80%;
    }
}